import React, { useEffect } from 'react'
import Select from 'react-select';
import { countries } from '../../constants/countries';
import { source } from '../../constants/filteroptions';
import { useDispatch, useSelector } from 'react-redux';
import { getLists } from './lists/redux/listActions';

const FilterField = ({ handleChange, values }) => {

    const lists = useSelector(state => state.list.lists);
    const dispatch = useDispatch();
    console.log(values);

    useEffect(() => {
        if (!lists) {
            dispatch(getLists());
        }
    }, [lists]);

    const listOptions = lists.map((attribute, index) => {
        return {
            value: attribute.id,
            label: attribute.name,
        };
    });

    switch (values.field.ans) {
        case 1:
            return <input type="text"
                className='form-control filter-input'
                onBlur={(e) => handleChange(e.target.value)}
                value={values.values} />
        case 2:
            return <Select
                isMulti
                className='filter-select filter-groups'
                options={listOptions}
                onChange={handleChange}
                value={values.values}
            />
        case 4:
            return <Select
                className='filter-select'
                options={source}
                onChange={handleChange}
                value={values.value}
            />
        case 5:
            return <Select
                className='filter-select'
                options={countries}
                onChange={handleChange}
                value={values.value}
            />
        case 6:
            return <input type="date"
                value={values.value}
                className='form-control filter-input'
                onChange={(e) => handleChange(e.target.value)} />
        case 7:
            return <input type="number"
                value={values.value}
                className='form-control filter-input'
                onChange={(e) => handleChange(e.target.value)} />
        default:
            break;
    }
}

export default FilterField