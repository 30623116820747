import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Logo from '../../assets/images/logo.png';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from './redux/authActions';
import { resetAuth } from './redux/authSlice';

const Login = () => {

    const location = useLocation();
    const message = location.state?.message;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetAuth());
        document.title = 'Login - AutoEmails'
    });

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const error = useSelector(state => state.auth.error);
    const loading = useSelector(state => state.auth.loading);

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        dispatch(loginUser(values));
        setSubmitting(false);
    };

    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <>
            <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form className="form w-100">
                        <div className="text-center mb-11">
                            <img className="img-fluid w-50 mb-3" src={Logo} alt="Logo" />
                            <h1 className="text-dark fw-bolder mb-3">Login</h1>
                            {error && <div className="alert alert-danger mt-5">Invalid credentials</div>}
                            {message && <div className="alert alert-success mt-5">{message}</div>}
                        </div>

                        <div className="fv-row mb-8">
                            <Field
                                type="email"
                                name="email"
                                placeholder="Email address"
                                className="form-control bg-transparent"
                                autoFocus
                            />
                            <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>

                        <div className="fv-row mb-8">
                            <Field
                                type="password"
                                name="password"
                                placeholder="Password"
                                className="form-control bg-transparent"
                            />
                            <ErrorMessage name="password" component="div" className="text-danger" />
                        </div>

                        <div className="d-grid mb-10">
                            <button type="submit" className="btn btn-dark" disabled={isSubmitting}>
                                {loading ? 'Logging in...' : 'Login'}
                            </button>
                        </div>

                        <div className="text-gray-500 text-center fw-semibold fs-6">
                            Not a member yet? <Link to="/auth/register" className="link-dark">Register</Link>
                        </div>
                        <div className="text-gray-500 text-center fw-semibold fs-6">
                            <Link to="/auth/forgot-password" className="link-dark">Forgot password?</Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default Login;
