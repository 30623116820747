import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { getAllCampaigns } from './redux/campaignActions';
import CampaignSchedule from './CampaignSchedule';

const CampaignView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const campaigns = useSelector(state => state.campaign.campaigns);
    const [tab, setTab] = useState(0);
    useEffect(() => {
        if (!campaigns || campaigns.length === 0) {
            dispatch(getAllCampaigns());
        }
    }, [campaigns, dispatch]);

    const campaign = campaigns.find((item) => item.id == id);
    console.log(campaign);
    return (
        <>
            <div id="kt_app_content_container" className="app-container container-xxl">
                <div className="row g-5 g-xl-10 mb-xl-10">
                    <div className="col-12 mb-md-5 mb-xl-10">
                        <div className="card mb-5 mb-xl-8">
                            {
                                campaign ?
                                    <>
                                        <div className="card-header border-0 pt-5 menu-border-bottom">
                                            <div className="card-title align-items-start flex-column">
                                                <div className='campaign-content-detail'>
                                                    <div className="campaign-content-preview">
                                                        <div className='cc' dangerouslySetInnerHTML={{ __html: campaign.content }} />
                                                    </div>
                                                    <div className='campaign-preview-info'>
                                                        <div>
                                                            <div>{campaign.name}</div>
                                                            <div>#{campaign.id} {campaign.status == 1 ? 'Sceduled at ' + campaign.date + ' ' + campaign.time : 'Sent'}</div>
                                                        </div>
                                                        <div className='campaign-sender-info'>
                                                            <div className='campaign-sender-info-item'>
                                                                <span>Subject</span>
                                                                <p>{campaign.subject}</p>
                                                            </div>
                                                            <div className='campaign-sender-info-item'>
                                                                <span>Sender name</span>
                                                                <p>{campaign.senderName}</p>
                                                            </div>
                                                            <div className='campaign-sender-info-item'>
                                                                <span>Sender email</span>
                                                                <p>{campaign.senderEmail}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab">
                                                    <Link to='' onClick={() => setTab(1)} className={`tablinks ${tab == 1 && 'active'}`}><h4>Overview</h4></Link>
                                                    {/* <Link to='' onClick={() => setTab(2)} className={`tablinks ${tab == 2 && 'active'}`}><h4>Overview</h4></Link> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body py-3">
                                            {
                                                tab == 1 &&
                                                <p>1</p>
                                            }
                                        </div>
                                    </>
                                    :
                                    'Loading...'
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CampaignView