import React, { useState } from 'react'
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import SenderDomain from './Sender.jsx/SenderDomain';
import SenderAdd from './Sender.jsx/SenderAdd';
function Settings() {
    const location = useLocation();
    const { pathname } = location;
    const parts = pathname.split('/');


    return (
        <>
            <div id="kt_app_content_container" className="app-container container-xxl">
                <div className="row g-5 g-xl-10 mb-xl-10">
                    <div className="col-12 mb-md-5 mb-xl-10">
                        <div className="card mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5 menu-border-bottom">
                                <h3 className="card-title align-items-start flex-column">
                                    <div className="tab">
                                        <Link to='' className={`tablinks ${!parts[3] || parts[3] == '' ? 'active' : 'active'}`}><h4>Senders & Domains</h4></Link>
                                        {/* <Link to='lists' className={`tablinks ${parts[3] === 'lists' ? 'active' : ''}`}><h4>Lists</h4></Link> */}
                                    </div>
                                </h3>
                            </div>
                            <div className="card-body py-3">
                                <Routes>
                                    <Route path='/' element={<SenderDomain />} />
                                    <Route path='/add' element={<SenderAdd />} />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings