import { createSlice } from '@reduxjs/toolkit';

export const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        subscribers: [],
        sender: [],
        loading: false,
        error: null,
        success: false,
    },
    reducers: {
        settingStart: state => {
            state.loading = true;
            state.error = null;
        },
        settingFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createSenderSuccess: (state, action) => {
            state.sender = action.payload;
            state.loading = false;
            state.success = true;
        },
        resetSetting: (state, action) => {
            state.success = null;
            state.error = null;
            state.loading = false;
        }
    },
});

export const { settingStart, settingFailure, createSenderSuccess, 
    resetSetting } = settingSlice.actions;

export default settingSlice.reducer;
