import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { createSender } from '../redux/settingActions';

const SenderAdd = () => {

    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [data, setData] = useState({
        name: '',
        email: '',
        domain: '',
    });

    const handleEmail = (e) => {
        let email = e.target.value;
        setData({ ...data, email: email, domain: email.split('@')[1] });
    }

    const handleSubmit = () => {

        if (data.name == '' || data.email == '' || data.domain == '') {
            setError('All fields are required');
            return;
        }

        setError('');
        dispatch(createSender(data));
    }

    return (
        <>
            <div className='sender-form'>
                <div>
                    <h2>New Sender</h2>
                </div>
                <div>
                    {
                        error ? 
                        <div className='alert alert-danger'>{error}</div>
                         : ''
                    }
                </div>
                <div className=''>
                    <label htmlFor="">From Name</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='Sender Name'
                        value={data.name}
                        onChange={(e) => setData({ ...data, name: e.target.value })} />
                </div>
                <div className=''>
                    <label htmlFor="">From Email</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='Sender Email'
                        onChange={handleEmail}
                        value={data.email}
                    />
                </div>
                <div className='mb-5'>
                    <label htmlFor="">Domain</label>
                    <input
                        type="text"
                        className="form-control"
                        value={data.domain}
                        placeholder='Domain'
                        onChange={(e) => setData({ ...data, domain: e.target.value })} />
                </div>
                <div className=''>
                    <button className="btn btn-dark" onClick={handleSubmit}>Authenticate</button>
                </div>
            </div>
        </>
    )
}

export default SenderAdd