import React from 'react'
import { Link } from 'react-router-dom'

const SenderDomain = () => {
    return (
        <>
            <div className='btns-headers'>
                <Link to={'add'} className="btn btn-dark">Add Identity</Link>
            </div>
            <div>
                <div className="table-responsive mt-10">
                    <table className="table align-top gs-0 gy-4">
                        <thead>
                            <tr className="fw-bold text-muted bg-light">
                                <td className='ps-4 min-w-200px'>Sender Name</td>
                                <td className='min-w-200px'>Email</td>
                                <td className=''>Email Status</td>
                                <td className='min-w-200px'>Domain</td>
                                <td className=''>Domain Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default SenderDomain