import React, { useEffect, useState } from 'react'
import FilterSubscriber from './FilterSubscriber'

const Filter = ({ customfields, handleFilter, initialFilter, segmentId, updateSegment}) => {

    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const IF = [
        {
            id: getRandomInt(9, 100),
            field: null,
            operand: null,
            value: null,
        },
    ];

    const [filters, setFilters] = useState(IF);

    useEffect(() => {
        if (initialFilter || initialFilter != null) {
            setFilters(initialFilter);
        }
    }, [initialFilter]);

    const addFilter = (filter) => {
        const updatedFilters = [...filters];
        const index = updatedFilters.findIndex(item => item.id === filter.id);

        if (index !== -1) {
            updatedFilters[index] = filter;
        } else {
            updatedFilters.push(filter);
        }

        setFilters(updatedFilters);
        handleFilter(updatedFilters);
    }

    const removeFilter = (id) => {
        const uf = [...filters];
        const fuf = uf.filter(item => item.id !== id);
        setFilters(fuf);
        handleFilter(fuf);
    };

    const handleAddCondition = () => {
        setFilters([...filters,
        {
            id: getRandomInt(9, 100),
            field: null,
            operand: null,
            value: null,
        },
        ]);
    }

    const handleClear = () => {
        const fuf = [{
            id: getRandomInt(9, 100),
            field: null,
            operand: null,
            value: null,
        }];
        setFilters(fuf);
        handleFilter([]);
    }

    const handleRefresh = () => {
        handleFilter(filters);
    }

    const handleSegment = () => {
        updateSegment(filters, segmentId);
    }


    return (
        <>
            <div className='filter-section'>
                {
                    filters.map((item, index) => {
                        return <FilterSubscriber key={index}
                            customfields={customfields}
                            addFilter={addFilter}
                            currentValue={item}
                            removeFilter={removeFilter}
                            id={item.id}
                            index={index}
                        />
                    })
                }
                <div className='filter-btns'>
                    {
                        segmentId &&
                        <button onClick={handleSegment} className='btn btn-dark btn-sm'>Save segment</button>
                    }
                    <button onClick={handleAddCondition} className='btn btn-dark btn-sm'>Add a condition</button>
                    <button onClick={handleRefresh} className='btn btn-dark btn-sm'>Refresh</button>
                    <button onClick={handleClear} className='btn btn-dark btn-sm'>Clear</button>
                </div>
            </div>
        </>
    )
}

export default Filter