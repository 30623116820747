import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CampaignAdd from './CampaignAdd';
import CampaignList from './CampaignList';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCampaigns } from './redux/campaignActions';

const Campaign = () => {

    const [addCampaign, setAddCampaign] = useState(false);
    const dispatch = useDispatch();
    const handleAddCampaign = () => {
        setAddCampaign(!addCampaign);
    }

    const campaigns = useSelector(state => state.campaign.campaigns);

    useEffect(() => {
        if (!campaigns || campaigns.length === 0) {
            dispatch(getAllCampaigns());
        }
    }, [campaigns, dispatch]);


    return (
        <>
            <div id="kt_app_content_container" className="app-container container-xxl">
                <div className="row g-5 g-xl-10 mb-xl-10">
                    <div className="col-12 mb-md-5 mb-xl-10">
                        <div className="card mb-5 mb-xl-8">
                            <div className="card-header border-0 pt-5 menu-border-bottom">
                                <h2 className="card-title align-items-start flex-column">
                                    <div className="tab">
                                        <Link to='' className={`tablinks active`}><h4>All Campaigns</h4></Link>
                                    </div>
                                </h2>
                            </div>
                            <div className="card-body py-3">
                                <div className='btns-headers'>
                                    <button onClick={handleAddCampaign} className='btn btn-dark'>Add campaign</button>
                                </div>
                                {
                                    campaigns ?
                                        <CampaignList campaigns={campaigns} /> :
                                        'Loading...'
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                addCampaign &&
                <CampaignAdd handleAddCampaign={handleAddCampaign} />
            }
        </>
    )
}

export default Campaign