import React from 'react'
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSubscriber } from './redux/subscriberActions';

const ContactTable = ({ subscribers }) => {
 
    const cusarr = useSelector(state => state.customAttribute.customAttributes);

    const dispatch = useDispatch();
    const handleDeleteContact = (id) => {
        dispatch(deleteSubscriber(id));
    }

    return (
        <>
            {
                subscribers &&
                <div>
                    <div className='table-count'>{subscribers.length} Contacts</div>
                    <div className="table-responsive mt-10">
                        <table className="table align-top gs-0 gy-4">
                            <thead>
                                <tr className="fw-bold text-muted bg-light">
                                    <td className='ps-4 min-w-300px bbs'>Email</td>
                                    <td className='min-w-200px'>Name</td>
                                    <td className='min-w-200px'>Phone</td>
                                    <td className='min-w-200px'>Company</td>
                                    <td className='min-w-200px'>Address Line1</td>
                                    <td className='min-w-200px'>Address Line2</td>
                                    <td className='min-w-100px'>City</td>
                                    <td className='min-w-100px'>State</td>
                                    <td className='min-w-100px'>Zip</td>
                                    <td className='min-w-200px'>Country</td>
                                    {cusarr && cusarr.map((item, key) =>
                                        <td className='min-w-200px' key={key}>{item.name}</td>
                                    )
                                    }
                                    <td className='min-w-200px'>Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    subscribers.map((item, key) => {
                                        return <tr key={key}>
                                            <td className='bbs'> <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">{item.email}</p></td>
                                            <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.first_name}&nbsp;{item.last_name}</p></td>
                                            <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.phone}</p></td>
                                            <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.company}</p></td>
                                            <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.address_line_1}</p></td>
                                            <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.address_line_2}</p></td>
                                            <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.city}</p></td>
                                            <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.state}</p></td>
                                            <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.zip}</p></td>
                                            <td> <p className="text-dark fw-bold d-block mb-1 fs-6">{item.country}</p></td>
                                            {cusarr.map((headerItem, headerIndex) => (
                                                <td key={headerIndex}> <p className="text-dark fw-bold d-block mb-1 fs-6">{item[headerItem.field]}</p></td>
                                            ))}
                                            <td> <IoClose size={20} className='cursor-pointer' onClick={() => handleDeleteContact(item.id)} /></td>
                                        </tr>
                                    }
                                    )
                                }
                            </tbody>
                        </table> 
                    </div>
                </div>
            }
        </>
    )
}

export default ContactTable