import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createCampaign } from './redux/campaignActions';
import { resetCampaign } from './redux/campaignSlice';


const CampaignContent = ({ emailData, handleAddCampaign }) => {
    const [content, setContent] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [tab, setTab] = useState(0);
    const [error, setError] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const loading = useSelector(state => state.campaign.loading);
    const success = useSelector(state => state.campaign.success);
    console.log(success);

    const dispatch = useDispatch();
    const handleSelect = (e) => {
        if (content === null) {
            setContent(e.target.value);
        } else {
            setContent(content + e.target.value);
        }
        setSelectedOption('');
    }

    const handleSaveCampaign = (stat) => {
        console.log(time)
        if (content == '' || content == null) {
            return;
        }

        if (stat == 1 && time == '' && date == '') {
            setError(true);
            return;
        }

        setError(false);
        dispatch(createCampaign({ ...emailData, content: content, stat:stat, time:time, date:date }));
    }

    useEffect(() => { 
        if (success) {
            dispatch(resetCampaign());
            handleAddCampaign();
        }
    }, [success, dispatch])

    return (
        <>
            <div className="campaign-content-container">
                {
                    tab == 0 ?
                        <>
                            <h2>Campaign details</h2>
                            <p>Enter email content or copy html</p>
                            <div className="campaign-content content-html">
                                <div className='row'>
                                    <div className="fv-row mb-4">
                                        <select value={selectedOption}
                                            className='form-control opt-select' onChange={handleSelect}>
                                            <option value="">Select a variable</option>
                                            <option value="${first_name}">First name</option>
                                            <option value="${last_name}">Last name</option>
                                            <option value="${company}">Company</option>
                                            <option value="${country}">Country</option>
                                            <option value="${city}">City</option>
                                            <option value="${phone}">Phone</option>
                                            <option value="${state}">State</option>
                                            <option value="${zip}">Zip</option>
                                        </select>
                                        <textarea
                                            cols={120}
                                            rows={15}
                                            className='p-4 form-control content-input'
                                            value={content}
                                            onChange={(e) => { setContent(e.target.value) }}></textarea>
                                    </div>
                                </div>
                                <div className='campaign-preview'>
                                    <div className="preview-container-html">
                                        <div dangerouslySetInnerHTML={{ __html: content }} />
                                    </div>
                                </div>
                            </div>
                            <div className="fv-row mb-4">
                                <button onClick={() => setTab(1)} className='btn btn-dark'>Next</button>
                            </div>
                        </>
                        :
                        <>
                            <h2>Campaign Schedule</h2>
                            <p>Send now or schedule for later</p>
                            <div className='campaign-schedule'>
                                <div className='my-3'>
                                    <button onClick={() => handleSaveCampaign(0)} className='btn btn-dark'>Send Now</button>
                                </div>
                                <div class="line-or">OR</div>
                                <div className='my-3 '>
                                    <input
                                        type="date"
                                        className='form-control mb-3 campaign-schedule-input'
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)} />
                                    <input
                                        type="time"
                                        className='form-control mb-3 campaign-schedule-input'
                                        value={time}
                                        onChange={(e) => setTime(e.target.value)}
                                    />
                                    {
                                        error &&
                                        <div className='alert alert-danger campaign-schedule-input'>
                                            Date & Time fields are required.
                                        </div>
                                    }

                                    <button onClick={() => handleSaveCampaign(1)} className='btn btn-dark mb-3'>Schedule</button>
                                </div>
                            </div>
                            {/* <div className="fv-row mb-4">
                                <button onClick={handleSaveCampaign} className='btn btn-dark'>
                                    {
                                        loading ? 'Loading...' :
                                            'Save Campaign'
                                    }
                                </button>
                            </div> */}
                        </>

                }
            </div>
        </>
    )
}

export default CampaignContent