import React, { useEffect, useState } from 'react'
import logo from '../assets/images/logo.png'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { Navigate, Route, Routes } from 'react-router-dom';
import { fetchUserData, isAuthenticated } from '../middleware'
import { useDispatch } from 'react-redux';
import { userUpdate } from '../pages/auth/redux/authSlice';
import Dashboard from '../pages/dashboard/Dashboard';
import Contacts from '../pages/contacts/Contacts';
import Campaign from '../pages/campaign/Campaign';
import CampaignView from '../pages/campaign/CampaignView';
import Settings from '../pages/settings/Settings';

const Mainlayout = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();


  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated()) {
        const userData = await fetchUserData();
        dispatch(userUpdate(userData));
        setUserData(userData);
      }
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    console.log(userData);
    if (userData) {
      document.title = 'Hi ' + capitalizeAndSplit(userData.name) + ' - AutoEmails'
    }
  }, [userData]);

  function capitalizeAndSplit(inputString) { 
    const firstWord = inputString.split(' ')[0];  // Split the string and get the first word
    return firstWord.charAt(0).toUpperCase() + firstWord.slice(1);  // Capitalize the first letter
  }

  if (loading) {
    return <p></p>;
  }

  if (!loading && !isAuthenticated() || userData === null) {
    return <Navigate to='/auth/login' />;
  }

  return (
    <>
      <div id="kt_app_body" data-kt-app-header-fixed="true" data-kt-app-header-fixed-mobile="true"
        data-kt-app-sidebar-enabled="true" data-kt-app-sidebar-fixed="true" data-kt-app-sidebar-push-header="true"
        data-kt-app-sidebar-push-toolbar="true" data-kt-app-sidebar-push-footer="true" className="app-default" >
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
            <div id="kt_app_header" className="app-header bg-light">
              <div className="app-container container-fluid d-flex align-items-stretch flex-stack"
                id="kt_app_header_container">
                {/* <div className="d-flex align-items-center d-lg-none d-block ms-n3" title="Show sidebar menu">
                  <div className="btn btn-icon btn-active-color-primary w-35px h-35px me-2"
                    id="kt_app_sidebar_mobile_toggle">
                    <i className="ki-outline ki-abstract-14 fs-2"></i>
                  </div>
                  <img alt='' src={logo} className="" />
                </div> */}
                <Navbar />
              </div>
            </div>


            <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
              <Sidebar />
              <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                <div className="d-flex flex-column flex-column-fluid">
                  <div id="kt_app_content" className="app-content flex-column-fluid">
                    <Routes>
                      <Route path='/' element={<Dashboard />} />
                      <Route path='/contacts/*' element={<Contacts />} />
                      <Route path='/campaigns/:id' element={<CampaignView />} />
                      <Route path='/campaigns/*' element={<Campaign />} />
                      <Route path='/settings/*' element={<Settings />} />
                    </Routes>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Mainlayout