import React, { useEffect, useState } from 'react'
import { IoClose } from "react-icons/io5";
import { defaultAttr } from '../../../constants/attributes';
import AddAttribute from './AddAttribute';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCustomAttribute, getCustomAttribute } from './redux/customAttributeActions';

const CustomAttributes = () => {

    const dispatch = useDispatch();
    const cusarr = useSelector(state => state.customAttribute.customAttributes);

    useEffect(() => {
        if (!cusarr || cusarr.length === 0) {
            dispatch(getCustomAttribute());
        }
    }, [dispatch, cusarr]);

    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = (state) => {
        setIsVisible(state);
    };

    const handleDeleteAttr = (id) => {
        dispatch(deleteCustomAttribute(id));
    }

    return (
        <>
            {isVisible && <div className="overlay" onClick={() => toggleVisibility(true)}></div>}
            <div className='btns-headers'>
                <button onClick={toggleVisibility} className='btn btn-dark'>Add Attribute</button>
            </div>
            <div className="table-responsive mt-10">
                <table className="table align-top gs-0 gy-4">
                    <thead>
                        <tr className="fw-bold text-muted bg-light">
                            <th className="ps-4 min-w-200px">ATTRIBUTE</th>
                            <th className="min-w-200px">TYPE</th>
                            <th className="min-w-200px">SYMBOL</th>
                            <th className="min-w-200px">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            defaultAttr.map((item, key) => {
                                return <tr key={key}>
                                    <td>
                                        <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">
                                            {item.name}
                                        </p>
                                    </td>
                                    <td>
                                        <p className="text-dark fw-bold d-block mb-1 fs-6">
                                            {item.type}
                                        </p>
                                    </td>
                                    <td>
                                        <p className="text-dark fw-bold d-block mb-1 fs-6">
                                            {item.tag}
                                        </p>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            })
                        }
                        {cusarr &&
                            cusarr.map((item, key) => {
                                return <tr key={key}>
                                    <td>
                                        <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">
                                            {item.name}
                                        </p>
                                    </td>
                                    <td>
                                        <p className="text-dark fw-bold d-block mb-1 fs-6">
                                            {item.type}
                                        </p>
                                    </td>
                                    <td>
                                        <p className="text-dark fw-bold d-block mb-1 fs-6">
                                            {item.tag}
                                        </p>
                                    </td>
                                    <td>
                                        <IoClose size={20} className='cursor-pointer' onClick={() => handleDeleteAttr(item.id)} />
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                isVisible &&
                <AddAttribute isVisible={isVisible} toggleVisibility={toggleVisibility} />
            }
        </>
    )
}

export default CustomAttributes