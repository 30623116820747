import React, { useEffect, useState } from 'react'
import SegmentAdd from './SegmentAdd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSegment, getSegments } from './redux/segmentActions';
import { IoClose } from 'react-icons/io5';
import { Link } from 'react-router-dom';

function Segments() {

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = (state) => {
    setIsVisible(state);
  };

  const dispatch = useDispatch();
  const segments = useSelector(state => state.segment.segments);

  useEffect(() => {
    if (!segments || segments.length === 0) {
      dispatch(getSegments());
    }
  }, [dispatch, segments]);

  const handleDeleteSegment = (id) => {
    dispatch(deleteSegment(id));
  }


  return (
    <>
      {isVisible && <div className="overlay" onClick={() => toggleVisibility(true)}></div>}
      <div className='btns-headers'>
        <button onClick={toggleVisibility} className='btn btn-dark'>Add segment</button>
      </div>
      <div className="table-responsive mt-10">
        <table className="table align-top gs-0 gy-4">
          <thead>
            <tr className="fw-bold text-muted bg-light">
              <th className="ps-4 min-w-200px">SEGMENT</th>
              <th className="min-w-200px">SUBSCRIBERS</th>
              <th className="min-w-200px">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {segments &&
              segments.map((item, key) => {
                return <tr key={key}>
                  <td>
                    <Link to={`/dashboard/contacts?segment=${item.id}`}>
                      <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">
                        {item.name}
                      </p>
                    </Link>
                  </td>
                  <td>
                    <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">
                      0
                    </p>
                  </td>
                  <td>
                    <IoClose size={20} className='cursor-pointer' onClick={() => handleDeleteSegment(item.id)} />
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
      {
        isVisible &&
        <SegmentAdd isVisible={isVisible} toggleVisibility={toggleVisibility} />
      }
    </>
  )
}

export default Segments