import React, { useEffect, useMemo, useState } from 'react'
import ContactAdd from './ContactAdd';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomAttribute } from '../customattr/redux/customAttributeActions';
import { defaultAttr } from '../../../constants/attributes';
import { exportSubscriber, getSubscribers } from './redux/subscriberActions';
import { getLists } from '../lists/redux/listActions';
import Filter from '../Filter';
import { useSearchParams } from 'react-router-dom';
import ContactTable from './ContactTable';
import { getSegments, updateSegment } from '../segments/redux/segmentActions';
import ContactImport from './ContactImport';


const ContactList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const listId = searchParams.get("listId");
  const listName = searchParams.get("listName");

  const segmentId = searchParams.get("segment");
  const [filtersSet, SetFiltersSet] = useState(false);

  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [initialFilter, setInitialFilter] = useState(null);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };


  const subscribers = useSelector(state => state.subscriber.subscribers);
  const loading = useSelector(state => state.subscriber.loading);
  const cusarr = useSelector(state => state.customAttribute.customAttributes);
  const segments = useSelector(state => state.segment.segments);
  const lists = useSelector(state => state.list.lists);

  useEffect(() => {
    if (listId === null) {
      if(!subscribers || subscribers.length == 0 && filtersSet == false){
        dispatch(getSubscribers());
      } 
      setInitialFilter(null);
    } else {
      const IF = [{
        id: 1000,
        field: { value: 'groups', label: 'Groups', opt: 2, ans: 2, cf: 0, },
        operand: { value: '=', label: 'Belongs to', mainfilter: '', opt: 2 },
        values: [{ value: listId, label: listName }],
      }];
      setInitialFilter(IF);
      dispatch(getSubscribers(IF));
    }

    if (!lists || lists.length === 0) {
      dispatch(getLists());
    }
    if (!cusarr || cusarr.length === 0) {
      dispatch(getCustomAttribute());
    }
    if (!segments || segments.length === 0) {
      dispatch(getSegments());
    } 
  }, [dispatch, listId, segments, cusarr, lists, subscribers]);


  useEffect(() => {
    if (segmentId && segments) {
      const allSegments = [...segments];
      const currentSegments = allSegments.filter(item => item.id == segmentId);
      if (currentSegments[0]) {
        setInitialFilter(JSON.parse(currentSegments[0].filters));
        dispatch(getSubscribers(JSON.parse(currentSegments[0].filters)));
      }
    }
  }, [segmentId, segments]);


  const initialValues = useMemo(() => {
    if (!cusarr) {
      return {};
    }
    const combinedAttributes = [...defaultAttr, ...cusarr];
    combinedAttributes.push({ field: "listId" });
    return combinedAttributes.reduce((acc, item) => {
      acc[item.field] = "";
      return acc;
    }, {});

  }, [cusarr]);


  const handleFilter = (filters) => {
    SetFiltersSet(true);
    dispatch(getSubscribers(filters));
  }

  const handleSegment = (filters, id) => {
    const data = {
      filters: filters,
      id: id
    };

    dispatch(updateSegment(data));
  }

  const handleExport = () => {
    dispatch(exportSubscriber());
  }

  const handleImport = () => {
    setImportVisible(!importVisible);
  }

  return (
    <>
      {isVisible && <div className="overlay" onClick={toggleVisibility}></div>}
      <div className='btns-headers'>
        <button onClick={handleImport} className='btn btn-dark mr-1'>Import contacts</button>
        <button onClick={handleExport} className='btn btn-dark mr-1'>Export contacts</button>
        <button onClick={toggleVisibility} className='btn btn-dark'>Add contact</button>
      </div>
      <div className='filterDiv'>
        <Filter
          customfields={cusarr}
          handleFilter={handleFilter}
          initialFilter={initialFilter}
          segmentId={segmentId}
          updateSegment={handleSegment} />
      </div>

      {
        !loading &&
        <ContactTable subscribers={subscribers} />
      }

      {isVisible &&
        <ContactAdd isVisible={isVisible} toggleVisibility={toggleVisibility} initialValues={initialValues} />
      }

      {importVisible &&
        <ContactImport isVisible={importVisible} handleImport={handleImport} />
      }

    </>
  )
}

export default ContactList