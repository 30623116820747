import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react';

export const campaignSlice = createSlice({
    name: 'campaign',
    initialState: {
        campaigns: [],
        loading: false,
        error: null,
        success: false,
        email: null,
        domain: null
    },
    reducers: {
        campaignStart: state => {
            state.loading = true;
            state.error = null;
        },
        campaignFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getCampaignsSuccess: (state, action) => {
            state.loading = false;
            state.campaigns = action.payload;
        },
        createCampaignSuccess: (state, action) => {
            state.campaigns = [...state.campaigns, action.payload];
            state.loading = false;
            state.success = true;
        },
        updateCampaignSuccess: (state, action) => {
            state.campaigns = action.payload;
            state.loading = false;
            state.success = true;
        },
        deleteCampaignSuccess: (state, action) => {
            console.log(action.payload);
            state.campaigns = state.campaigns.filter(campaign => campaign.id != action.payload);
            state.loading = false;
            state.success = true;
        },
        emailVerificationSuccess: (state, action) => {
            state.email = action.payload.email;
            state.domain = action.payload.domain;
            state.loading = false;
            state.success = true;
        },
        resetCampaign: (state, action) => {
            state.success = null;
            state.error = null;
            state.loading = false;
            state.email = null;
            state.domain = null;
        }
    },
});

export const { campaignStart, campaignFailure, getCampaignsSuccess,
    createCampaignSuccess, updateCampaignSuccess, deleteCampaignSuccess,
    resetCampaign, emailVerificationSuccess } = campaignSlice.actions;

export default campaignSlice.reducer;
