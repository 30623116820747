import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSubscribers } from '../contacts/subscribers/redux/subscriberActions';

const Dashboard = () => {
    const subscribers = useSelector(state => state.subscriber.subscribers);
    console.log(subscribers);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!subscribers || subscribers.length === 0) {
            dispatch(getSubscribers()); // Invoke getSubscribers as a function
        }
    }, [dispatch, subscribers])

    return (
        <>
            <div className="row mx-5">
                <div className="col-3">
                    <div className="card">
                        <div className="card-body">
                            <h5>Total Contacts</h5>
                            <h2>{subscribers.length}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard