import React, { useEffect } from 'react'
import Logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from './redux/authActions';


const Forgotpassword = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'Forgot Password - AutoEmails'
  }, []);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });
  const error = useSelector(state => state.auth.error);

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(forgotPassword(values));
    setSubmitting(false);
  };
  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="form w-100">
            <div className="text-center mb-11">
              <img className="img-fluid w-50 mb-3" src={Logo} alt="Logo" />
              <h1 className="text-dark fw-bolder mb-3">Forgot Password</h1>
              {error && <div className="alert alert-danger mt-5">{error.response.data.message}</div>}
            </div>

            <div className="fv-row mb-8">
              <Field
                type="email"
                name="email"
                placeholder="Email address"
                className="form-control bg-transparent"
                autoFocus
              />
              <ErrorMessage name="email" component="div" className="text-danger" />
            </div>

            <div className="d-grid mb-10">
              <button type="submit" className="btn btn-dark" disabled={isSubmitting}>
                {isSubmitting ? 'Loading...' : 'Send password reset link'}
              </button>
            </div>

            <div className="text-gray-500 text-center fw-semibold fs-6">
              <Link to='/auth/login' className="link-dark">Login</Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Forgotpassword