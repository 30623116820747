import React from 'react'
import { IoClose } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteCampaign } from './redux/campaignActions'

const CampaignList = ({ campaigns }) => {

    const dispatch = useDispatch();

    const handleDeleteCampaign = (id)=>{
        dispatch(deleteCampaign(id));
    }

    return (
        <>
            <div className="table-responsive mt-10">
                <table className="table align-top gs-0 gy-4">
                    <thead>
                        <tr className="fw-bold text-muted bg-light">
                            <th className="ps-4 min-w-200px">Name</th>
                            <th className="min-w-200px">Date Created</th>
                            <th className="min-w-200px">Status</th>
                            <th className="min-w-200px">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            campaigns.map((item, key) => {
                                return <tr key={key}>
                                    <td>
                                        <Link to={`${item.id}`} className='campaign-title-link'>
                                            <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">
                                                {item.name}
                                            </p>
                                        </Link>
                                    </td>
                                    <td>
                                        <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">
                                            {item.created_at}
                                        </p>
                                    </td>
                                    <td>
                                        <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">
                                            {item.status == 2 ? 'Sent' : (item.status == 1 ? 'Scheduled' : 'Sent')}
                                        </p>
                                    </td>
                                    <td>
                                        <IoClose size={20} className='cursor-pointer' onClick={() => handleDeleteCampaign(item.id)} />
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CampaignList