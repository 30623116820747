import api from '../../../config';
import { createSenderSuccess, settingFailure, settingStart } from './settingSlice';


export const createSender = (values) => async (dispatch) => {
    try {
        dispatch(settingStart());
        const response = await api.post('/setting/sender/store', values);
        dispatch(createSenderSuccess(response.data.sender));
    } catch (error) {
        dispatch(settingFailure(error.response.data.message));
    }
};
