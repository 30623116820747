import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { mainfilter, operators1, operators2, operators3, operators4 } from '../../constants/filteroptions';
import FilterField from './FilterField';
import { defaultAttr } from '../../constants/attributes';
import { IoClose } from 'react-icons/io5';

const FilterSubscriber = ({ customfields, addFilter, currentValue, removeFilter, id, index }) => {

    const [oldValues, setOldValues] = useState(currentValue);
    const [option2, setOption2] = useState(null);

    useEffect(() => {
        setOldValues(currentValue);
    }, [currentValue]);

    useEffect(() => {
        if (oldValues && oldValues.field !== null && oldValues.operand !== null && oldValues.values !== null) {
            getOption2(oldValues.field);
        }
    }, [oldValues]);

    const combinedAttributes = [...defaultAttr, ...customfields];
    const attributesArray = combinedAttributes.map((attribute, index) => {
        return {
            value: attribute.field,
            label: attribute.name,
            ans: attribute.type === 'TEXT' ? 1 : attribute.type === 'NUMBER' ? 7 : 6,
            opt: attribute.type === 'TEXT' ? 1 : attribute.type === 'NUMBER' ? 1 : 4,
            cf: attribute.user_id ? 1 : 0,
        };
    });

    const combinedMainFilters = [...mainfilter, ...attributesArray];

    const handleSelect1Change = selectedOption => {
        setOldValues({ id: id, field: selectedOption, operand: null, values: null });
        setOption2(null);
        getOption2(selectedOption);

    };

    const getOption2 = (selectedOption) => {
        let newOption2 = null;
        switch (selectedOption.opt) {
            case 1:
                newOption2 = operators1;
                break;
            case 2:
                newOption2 = operators2;
                break;
            case 3:
                newOption2 = operators3;
                break;
            case 4:
                newOption2 = operators4;
                break;
            default:
                newOption2 = null;
        }
        setOption2(newOption2);
    }

    const handleSelect2Change = selectedOption => {
        if (oldValues.field) {
            const newValues = {
                ...oldValues,
                operand: selectedOption,
            };
            setOldValues(newValues);
        }

    };


    const handleChange = (value) => {
        const newValues = {
            ...oldValues,
            values: value
        };
        addFilter(newValues, id);

    }

    return (
        <>
            <div className='filter-inner mt-2'>
                <Select
                    value={oldValues.field}
                    onChange={handleSelect1Change}
                    className='filter-select'
                    placeholder="Choose a filter"
                    options={combinedMainFilters}
                />
                {
                    option2 &&
                    <Select
                        value={oldValues.operand}
                        onChange={handleSelect2Change}
                        className='filter-select'
                        placeholder="Choose an operator"
                        options={option2}
                    />
                }
                {
                    oldValues.operand &&
                    <FilterField handleChange={handleChange} values={oldValues} />
                }
                {
                    index !== 0 &&
                    <div>
                        <IoClose size={20} className='cursor-pointer' onClick={() => removeFilter(id)} />
                    </div>
                }

            </div>
        </>
    )
}

export default FilterSubscriber