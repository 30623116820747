import { createSlice } from '@reduxjs/toolkit';

export const segmentSlice = createSlice({
    name: 'segment',
    initialState: {
        segments: [],
        loading: false,
        error: null,
        success: false,
    },
    reducers: {
        segmentStart: state => {
            state.loading = true;
            state.error = null;
        },
        segmentFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getSegmentsSuccess: (state, action) => {
            state.loading = false;
            state.segments = action.payload;
        },
        createSegmentSuccess: (state, action) => {
            state.segments = [...state.segments, action.payload];
            state.loading = false;
            state.success = true;
        },
        updateSegmentSuccess: (state, action) => {
            state.segments = action.payload;
            state.loading = false;
            state.success = true;
        },
        deleteSegmentSuccess: (state, action) => {
            state.segments = state.segments.filter(segment => segment.id != action.payload);
            state.loading = false;
            state.success = true;
        },
        resetSegment: (state, action) => {
            state.success = null;
            state.error = null;
            state.loading = false;
        }
    },
});

export const { segmentStart, segmentFailure, getSegmentsSuccess, createSegmentSuccess,
    deleteSegmentSuccess, resetSegment, updateSegmentSuccess } = segmentSlice.actions;

export default segmentSlice.reducer;
