import React, { useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import api from '../../../config';
import Select from 'react-select';
import { defaultAttr } from '../../../constants/attributes';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscribers, importSubscriber } from './redux/subscriberActions';
import { FileUploader } from 'react-drag-drop-files';
import { FaFileCsv } from 'react-icons/fa';
import { resetSubscriber } from './redux/subscriberSlice';

const ContactImport = ({ handleImport, isVisible }) => {

    const [csvFile, setCsvFile] = useState(null);
    const [headers, setHeaders] = useState(null);
    const [fieldMappings, setFieldMappings] = useState({});
    const dispatch = useDispatch();

    const loading = useSelector(state=>state.subscriber.loading);
    const success = useSelector(state=>state.subscriber.success);

    useEffect(()=>{
        if(success){
            dispatch(resetSubscriber());
            handleImport();
        }
    },[success])

    const handleChange = (file) => {
        setCsvFile(file);
        // Extract headers from the CSV file
        const reader = new FileReader();
        reader.onload = (event) => {
            const text = event.target.result;
            const lines = text.split('\n');
            const headers = lines[0].split(',');
            setHeaders(headers);
        };
        reader.readAsText(file);
    };

    const handleFieldChange = (fieldName, selectedOption) => {
        setFieldMappings(prevMappings => ({
            ...prevMappings,
            [fieldName]: selectedOption.value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!fieldMappings){
            alert('Map atleast one field');
        }

        const formData = new FormData();
        formData.append('csv_file', csvFile);
        formData.append('fieldMapping', JSON.stringify(fieldMappings));
        dispatch(getSubscribers());
        dispatch(importSubscriber(formData));
    };



    const cusarr = useSelector(state => state.customAttribute.customAttributes);

    const combinedAttributes = [...defaultAttr, ...cusarr];
    const attributesArray = combinedAttributes.map((attribute, index) => {
        return {
            value: attribute.field,
            label: attribute.name,
            cf: attribute.user_id ? 1 : 0,
        };
    });

    const defaultOption = { label: 'Skip this column', value: null, cf: 0 };

    const options = [defaultOption, ...attributesArray];

    const fileTypes = ["CSV"];

    const dropzoneInner = (
        <div className="container">
            <div className="box box-1">
                <FaFileCsv color='green' size={50} />
            </div>
            <div className="box box-2">
                Drag or drop a CSV
            </div>
        </div>
    );

    return (
        <div className={`slide ${isVisible ? 'show' : ''} ${headers ? 'wider' : ''}`}>
            <div className="slideContainer">
                <div className='slideHeader'>
                    <h3>Import contacts</h3>
                    <div className='slideClose' onClick={handleImport}>
                        <IoClose size={25} />
                    </div>
                </div>
                <div className='slideContent'>
                    {
                        !headers ?
                            <form>
                                <FileUploader
                                    handleChange={handleChange}
                                    name="file"
                                    types={fileTypes}
                                    multiple={false}
                                    children={dropzoneInner}
                                />
                            </form>
                            :
                            <div>
                                <span>Please map the csv columns with fields</span>
                                <div className='map-row mt-5'>
                                    <div className='map-title'><h5>Columns</h5></div>
                                    <div className='map-select'><h5>Belongs to</h5></div>
                                </div>
                                {headers.map((item, key) =>
                                    <div key={key} className='map-row'>
                                        <div className='map-title'>{item}</div>
                                        <div className='map-select'>
                                            <Select
                                                defaultValue={defaultOption}
                                                className='filter-select'
                                                placeholder="Choose a Field"
                                                options={options}
                                                onChange={(selectedOption) => handleFieldChange(key, selectedOption)}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className='mt-5'>
                                    <button onClick={handleSubmit} className='btn btn-dark'>
                                        {loading ? 'loading...':'Import'}
                                    </button>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ContactImport