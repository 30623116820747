import api from '../../../../config';
import {
    createSubscriberSuccess,
    deleteSubscriberSuccess, exportSubscriberSuccess, getSubscriberSuccess,
    importSubscriberSuccess,
    subscriberFailure, subscriberStart
} from './subscriberSlice';
import { saveAs } from 'file-saver';


export const createSubscriber = (values) => async (dispatch) => {
    try {
        dispatch(subscriberStart());
        const response = await api.post('/contacts/store', values);
        dispatch(createSubscriberSuccess(response.data.contacts));
    } catch (error) {
        console.log(error);
        dispatch(subscriberFailure(error.response.data.message));
    }
};

export const getSubscribers = (filters) => async (dispatch) => {
    try {
        dispatch(subscriberStart());
        const response = await api.post('/contacts/get', { filters: filters });
        dispatch(getSubscriberSuccess(response.data.contacts));
    } catch (error) {
        dispatch(subscriberFailure(error.response.data.error));
    }
};

export const deleteSubscriber = (id) => async (dispatch) => {
    try {
        dispatch(subscriberStart());
        const response = await api.get('/contacts/delete/' + id);
        dispatch(deleteSubscriberSuccess(response.data.id));
    } catch (error) {
        dispatch(subscriberFailure(error.response.data.error));
    }
};

export const exportSubscriber = () => async (dispatch) => {
    try {
        dispatch(subscriberStart());
        const response = await api.get('/contacts/export');
        const blob = new Blob([response.data], { type: 'text/csv' });
        saveAs(blob, 'contacts.csv');
        dispatch(exportSubscriberSuccess());
    } catch (error) {
        dispatch(subscriberFailure(error.response.data.error));
    }
};

export const importSubscriber = (values) => async (dispatch) => {
    try {
        dispatch(subscriberStart());
        await api.post('/contacts/import', values, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        dispatch(importSubscriberSuccess());
    } catch (error) {
        console.log(error);
        dispatch(subscriberFailure(error.response.data.message));
    }
};
