import { createSlice } from '@reduxjs/toolkit';

export const subscriberSlice = createSlice({
    name: 'subscriber',
    initialState: {
        subscribers: [],
        loading: false,
        error: null,
        success: false,
    },
    reducers: {
        subscriberStart: state => {
            state.loading = true;
            state.error = null;
        },
        subscriberFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        createSubscriberSuccess: (state, action) => {
            state.subscribers = action.payload;
            state.loading = false;
            state.success = true;
        },
        getSubscriberSuccess: (state, action) => {
            state.loading = false;
            state.subscribers = action.payload;
        },
        updateSubscriberSuccess: (state, action) => {
            state.subscribers = [...state.subscribers, action.payload];
            state.loading = false;
            state.success = true;
        },
        deleteSubscriberSuccess: (state, action) => {
            state.subscribers = state.subscribers.filter(subscriber => subscriber.id != action.payload);
            state.loading = false;
            state.success = true; 
        },
        exportSubscriberSuccess: (state, action) => {
            state.loading = false; 
            state.success = true;
        },
        importSubscriberSuccess: (state, action) => {
            state.loading = false; 
            state.success = true;
        }, 
        resetSubscriber: (state, action) => {
            state.success = null;
            state.error = null;
            state.loading = false;
        }
    },
});

export const { subscriberStart, subscriberFailure, createSubscriberSuccess,
    getSubscriberSuccess, updateSubscriberSuccess,
    deleteSubscriberSuccess, resetSubscriber, exportSubscriberSuccess, 
    importSubscriberSuccess } = subscriberSlice.actions;

export default subscriberSlice.reducer;
