import React, { useEffect, useState } from 'react'
import ListAdd from './ListAdd';
import { useDispatch, useSelector } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import { deleteList, getLists } from './redux/listActions';
import { Link } from 'react-router-dom';

const Lists = () => {

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = (state) => {
    setIsVisible(state);
  };

  const dispatch = useDispatch();
  const lists = useSelector(state => state.list.lists);

  useEffect(() => {
    if (!lists || lists.length === 0) {
      dispatch(getLists());
    }
  }, [dispatch, lists]);

  const handleDeleteList = (id) => {
    dispatch(deleteList(id));
  }

  return (
    <>
      {isVisible && <div className="overlay" onClick={() => toggleVisibility(true)}></div>}
      <div className='btns-headers'>
        <button onClick={toggleVisibility} className='btn btn-dark'>Add list</button>
      </div>
      <div className="table-responsive mt-10">
        <table className="table align-top gs-0 gy-4">
          <thead>
            <tr className="fw-bold text-muted bg-light">
              <th className="ps-4 min-w-200px">LIST</th>
              <th className="min-w-200px">SUBSCRIBERS</th>
              <th className="min-w-200px">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {lists &&
              lists.map((item, key) => {
                return <tr key={key}>
                  <td>
                    <Link to={`/dashboard/contacts?listId=${item.id}&listName=${item.name}`} >
                      <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">
                        {item.name}
                      </p>
                    </Link>
                  </td>
                  <td>
                    <p className="ps-4 text-dark fw-bold d-block mb-1 fs-6">
                      0
                    </p>
                  </td>
                  <td>
                    <IoClose size={20} className='cursor-pointer' onClick={() => handleDeleteList(item.id)} />
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
      {
        isVisible &&
        <ListAdd isVisible={isVisible} toggleVisibility={toggleVisibility} />
      }
    </>
  )
}

export default Lists